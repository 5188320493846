// colors 
$black: #141616;
$gray: #1E2121;
$green1: #4AFEBF;
$green2: #51EEDA; 

$red: #D84747;

$grad: linear-gradient(180deg, #4AFEBF 0%, #51EEDA 100%);


// breakpounts

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1300px;

// background images

$dashed: '../pics/dashed.svg';
$use-link: '../pics/use-link.svg';
$arrow: '../pics/arrow.svg';

// background images exchange hover
$e-1: '../pics/exchange/gate-hover.svg';
$e-2: '../pics/exchange/zb-hover.svg';
$e-3: '../pics/exchange/aex-hover.svg';
$e-4: '../pics/exchange/uniswap-hover.svg';
$e-5: '../pics/exchange/quickswap-hover.png';
$e-6: '../pics/exchange/pancake-hover.svg';
