* {
    box-sizing: border-box;
    outline: 0;
}

html, body {
    min-width: 375px;
    background-color: $black;
    color: #fff;
    font-family: 'Acherus Feral', sans-serif;
    font-weight: 300;
    scroll-behavior: smooth;
}

body {
    @include text-main(); 
}

.wrapper {
    overflow: hidden;
}

b, strong {
    font-weight: bold;
}


h1, .h1 {
    @include h1(); 
}
h2, .h2 {
    @include h2(); 
}
h3, .h3 {
    @include h3(); 
}

p {
    a {
        color: $green2;
        text-decoration: underline;
        transition: text-decoration 0.2s ease;
        &:hover {
            text-decoration: none;
        }
    }
}

a {
    color: inherit;
}

.divider {
    width: 50%;
    height: 1px;
    background-color: $green2;
    position: relative;
    &::before {
        content: '';
        height: 2px;
        width: 56px;
        background-image: url($dashed);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &.left {
        &::before {
            right: -66px;
        }
    }
    &.right {
        margin-left: 50%;
        &::before {
            left: -66px;
        }
    }
}

.socials {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
        padding: 3px;
    }
    img {
        display: block;
        border-radius: 50%;
        width: 25px;
        height: 25px;
    }

    a {
        display: block;
        border-radius: 50%;
        &:hover {
            box-shadow: 0px 4px 40px #66FDD0;
        }
    }

    @media(min-width: $xl) {
        img {
            width: 30px;
            height: 30px;
        }
    }
}

.text-grad {
    color: $green1;
    @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
        background-image: $grad;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
    }

}

.btn {
    display: flex;
    height: 55px;
    color: $black;
    border-radius: 10px;
    background: $grad;
    border: 0;
    width: 184px;
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &:hover {
        background: #21CE92;
        color: #fff;
    }
}



// cookies block

.sec-cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    height: auto;
    max-height: 100vh;
    width: 100%;
    background-color: $gray;
    padding: 30px 0 20px;
    z-index: 100;
    font-size: 12px;
    line-height: 20px;
    overflow: auto;

    .h3 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 1em;
    }

    p {
        margin: 1em 0;
    }

    .btn {
        margin: 20px auto;
    }

    @media(min-width: $lg) {
        padding: 50px 0 40px;  

        .btn {
            width: 120px;
            margin: 0 0 0 auto;
        }
    }
}
