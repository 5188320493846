//- Backgounds 

.sec-bg {
    display: block;
    position: absolute;

    &.bg-left {
        left: 0;
    }

    &.bg-right {
        right: 0;
    }
}

// Intro -------------------- 
.sec-intro {
    padding: 100px 0 80px;

    h1 {
        margin-bottom: 2px;
    }

    .h1_sub {
        font-weight: bold;
        margin: 0 0 2em;
    }

    .btn {
        width: 100%;
        max-width: 310px;
        margin-top: 32px;
    }

    @media(min-width: $lg) {
        padding: 140px 0 80px;
  
        .container {
            display: flex;
            align-items: center;
            position: relative;
            min-height: 570px;
        }

        .btn {
            margin-top: 56px;
        }
        .btn-outline {
            background: transparent;
            border: 1px solid #4afebf;
            color: #4afebf;
        }
        h1 {
            margin-bottom: 12px;
        }
    }
}

.btn-404 {
    background: transparent;
    border: 1px solid #4afebf;
    color: #4afebf;
}

.intro__img {
    img, svg {
        display: block;
        max-width: 500px;
        width: 100%;
        height: auto;
        margin-bottom: 40px;
    }
    .abs {
        position: absolute;
    }
    @media(min-width: $sm) {
        img, svg {
            width: 500px;
        }
    }
    @media(min-width: $md) {
        img, svg {
            width: 620px;
            margin-bottom: 0;
            max-width: none;
        }
    }
    @media(min-width: $lg) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-60%);
    }
    @media(min-width: $xl) {
        left: 54%;
        top: 50%;
        transform: translateY(-50%);
        img, svg {
            display: block;
            width: auto;
        }
    }
}

.intro__text {
    @media(min-width: $lg) {
        position: absolute;
        width: 51%;
    }  
    @media(min-width: $xl) {
        width: 50%;
    }
}

// Blockhains -------------------- 

.sec-distrib {
    position: relative;
    padding: 50px 0;

    h2 {
        margin-bottom: 32px;
    }

    @media(min-width: $lg) {
        padding: 100px 0 10px;
        h2 {
            margin-bottom: 56px;
        }
        .sec-bg {
            width: 320px;
            height: 710px;
        }
    }
    @media(min-width: $xl) {
        .sec-bg {
            width: 400px;
            height: 886px;
            top: 40px;
        }
    }
     
}

.distrib__row {
    background: #1E2121;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    max-width: 100%;

    .legend {
        display: block;
        font-size: 14px;
        opacity: 0.5;
        margin-bottom: 2px;
    }

    .sum {
        font-weight: bold;
    }

    .col {
        width: 50%;
        margin-bottom: 16px;
        flex-basis: auto;
        &.col-right {
            text-align: right;
        }
    }

    &.active {
        .distrib__schedule-toggle {
            border-color: transparent;
            color: #51EEDA;
        }  
        .distrib__schedule {
            display: block;
        }
    }

    &.header {
        background-color: transparent;
        padding-bottom: 16px;
        margin-bottom: 0;

        .legend {
            display: block;
            font-size: 18px;
            line-height: 24px;
            opacity: 0.9;
        }
        .sum {
            display: block;
            font-size: 14px;
            line-height: 24px;
        }
        span.sum {
            opacity: 0.5;
            font-weight: 300;
        }
        b.sum {
            color: #4AFEBF;
            opacity: 1;
        }
    }

    @media(min-width: $lg) {
        padding: 20px 24px;
        .legend {
            display: none;
        }

        .row {
            align-items: center;
        }

        .col {
            width: 18.75%;
            margin-bottom: 0;
        }
    }
}

.distrib__logo {
    display: inline-block;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    margin-bottom: 16px;
    font-size: 0;
    img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
    }
    span {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        line-height: 27px;  
    }
    .i-ex {
        max-width: 15px;
        margin-top: -5px;
    }

    &:hover {
        opacity: 0.6;
    }

    @media(min-width: $lg) {
        margin-bottom: 0;
    }
}

.distrib__schedule-toggle {
    border-bottom: 1px solid rgba(#fff, 0.8);
    cursor: pointer;
    &.active,
    &:hover {
        border-color: transparent;
        color: #51EEDA;
    }
}

.distrib__schedule {
    background-color: #141616;
    border-radius: 20px;
    padding: 20px 20px 0;
    margin-top: 16px;
    /*display: none;*/

    .header {
        width: 100%;
        padding: 0 20px;
        align-self: flex-start;

        .title {
            font-weight: bold;
            margin-bottom: 12px;
        }
        .lock-date {
            display: block;
            font-size: 14px;
            line-height: 20px;
            opacity: 0.5;
            margin-bottom: 16px;
        }
    }
    .schedule {
        width: 100%;
        font-weight: 300;
        font-size: 14px;
        line-height: 160%;
        color: rgba(#fff, 0.8);
        display: flex;
        flex-wrap: wrap;
        b {
            display: block;
            color: rgba(#fff, 1);
            white-space: nowrap;
        }

        div {
            width: 50%;
            margin-bottom: 24px;
            padding: 0 20px;
        }
    }

    @media(min-width: $md) {
        .schedule {
            div {
                width: 33.333%;
            }
        }
    }

    @media(min-width: $lg) {
        .header {
            width: 25%;       
        }
        .schedule {
            width: 75%;
            padding-top: 6px;
            div {
                width: 25%;
            }
        }
    }

    @media(min-width: $xl) {
        .header {
            width: 20%; 
        }
        .schedule {
            width: 80%;
            div {
                width: 20%;
            }
        }
    }
}


// DAO statistics -------------------- 

.sec-daostat {
    position: relative;
    padding: 0 0 50px 0;

    h2 {
        margin-bottom: 32px;
    }

    ul.daostat-links {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding: 4px 0;

            a {
                display: block;
                padding: 6px 12px;
                &.inactive {
                    text-decoration: none;
                    color: #fff;
                    
                    &:hover {
                        text-decoration: none;
                        background-color: #1E2121;
                    }
                }

                &.active {
                    text-decoration: none;
                    color: #4AFEBF;
                    background-color: #1E2121;

                    &:hover {
                        cursor: pointer;
                    }

                }
            }
        }
    }

    .daostat-chart {
        max-width: 100%;
    }

    @media(min-width: $lg) {
        padding: 100px 0 100px;
        h2 {
            margin-bottom: 56px;
        }
        .sec-bg {
            width: 320px;
            height: 710px;
        }
    }
    @media(min-width: $xl) {
        .sec-bg {
            width: 400px;
            height: 886px;
            top: 40px;
        }
    }
    @media(max-width: $sm) {

        .daostat-chart {
            max-height: 240px;
        }
        .daostat-overlay {
            font-size: 36px !important;

            & > span {
                display: block;
                margin: 55px 0 45px;
                line-height: 25px;
            }
        }
    }
     
}

// Burn statistics -------------------- 

.sec-burnstat {
    position: relative;
    padding: 0 0 50px 0;

    h2 {
        margin-bottom: 32px;
    }

    .burnstat-chart {
        max-width: 100%;
    }

    @media(min-width: $lg) {
        padding: 100px 0 100px;
        h2 {
            margin-bottom: 56px;
        }
        .sec-bg {
            width: 320px;
            height: 710px;
        }
    }
    @media(min-width: $xl) {
        .sec-bg {
            width: 400px;
            height: 886px;
            top: 40px;
        }
    }
    @media(max-width: $sm) {

        .burnstat-chart {
            max-height: 240px;
        }
        .burnstat-overlay {
            font-size: 36px !important;

            & > span {
                display: block;
                margin: 55px 0 45px;
                line-height: 25px;
            }
        }
    }
     
}

// Utilization -------------------- 

.sec-util {
    position: relative;
    padding: 50px 0;

    h2 {
        margin-bottom: 30px;
    }

    .h2 {
        margin-bottom: 0;
    }

    @media(min-width: $lg) {

        .distrib__row {
            .row > div:first-child {
                
                    text-align: right;
                    font-weight: bold;
                
            }
        }

        padding: 70px 0 20px;
        h2 {
            margin-bottom: 50px;
        }
    }

    @media(min-width: $xl) {
        padding: 110px 0 30px;
        h2 {
            margin-bottom: 60px;
        }

        .sec-bg {
            width: 360px;
            height: 843px;
            top: 40px;
        }
    }
}



// Buy -------------------- 

.sec-buy {
    position: relative;
    padding: 50px 0;

    h2 {
        margin-bottom: 30px;
    }

    .h2 {
        margin-bottom: 0;
    }

    .list__row {
        margin-top: 50px;
    }

    .pdf-icon {
        width: 100%;
        height: 12rem;
        position: relative;
        padding: 30px;
        margin-bottom: 20px;
        color: #fff;
        font-size: 1.2em;
        font-weight: bold;
        flex-grow: 0;
        background-color: #1e2121;
        box-shadow: 0 5px 25px -10px rgb(0 0 0 / 16%);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;   
        text-align: center;
        a {
           text-decoration: none;
           display: flex;
           align-items: center;
        }
        img {
            max-width: 75px;
        }
    }

    @media(min-width: $lg) {
        padding: 70px 0 50px;
        h2 {
            margin-bottom: 50px;
        }
    }

    @media(min-width: $xl) {
        padding: 200px 0 200px;
        h2 {
            margin-bottom: 60px;
        }

        .sec-bg {
            width: 360px;
            height: 843px;
            top: 40px;
        }
    }
}

.sec-buy__item {
    margin-bottom: 20px;
}

.buy__logo {
    display: inline-block;
    position: relative;
    font-size: 0;
    line-height: 0;

    img {
        width: auto;
        display: block;
        height: 62px;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    &:after {
        content: '';
        display: block;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-size: cover;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &.logo-1:after {
        background-image: url($e-1);
    }
    &.logo-2:after {
        background-image: url($e-2);
    }
    &.logo-3:after {
        background-image: url($e-3);
    }
    &.logo-4:after {
        background-image: url($e-4);
    }
    &.logo-5:after {
        background-image: url($e-5);
    }
    &.logo-6:after {
        background-image: url($e-6);
    }        
    @media(min-width: $lg) {
        img {
            height: 69px;
        }
    }
}


// Roadmap -------------------- 

.sec-roadmap {
    position: relative;
    padding: 50px 0 80px;

    h2 {
        margin-bottom: 10px;
    }

    .h2 {
        margin-bottom: 0;
    }

    .sec-bg {
        bottom: 0;
    }

    @media(min-width: $lg) {
        padding: 70px 0 120px;
        h2 {
            margin-bottom: 20px;
        }
    }

    @media(min-width: $xl) {
        padding: 110px 0 160px;
        h2 {
            margin-bottom: 30px;
        }
    }
}

.roadmap__help {
    padding-bottom: 40px;
}

.roadmap__slider {
    &:hover {
        .roadmap__slider-arrow {
            visibility: hidden;
            opacity: 0;
            transition: visibility 1s, opacity 1s ease-out;
        }
    }
    .roadmap__slider-arrow {
        position: absolute;
        top: 50%;
        right: 10%;
        z-index: 100;
        visibility: visible;
        opacity: 1;
    }
    .roadmap__slider-wrap {
        padding: 80px 0;
    }
    .content {
        display: flex;
        align-items: flex-start;
        position: relative; 
        width: calc(12 * 240px);
        &:hover {
            cursor: move;
        }
    }

    .l-area {
        position: absolute;
        width: 100%;
        left: 0;

        &:after {
            content: '';
            display: block;
            height: 4px;
        }

        span {
            position: absolute;
            font-weight: bold;
            left: 0;
            bottom: 8px;
        }

        &.a-1 {
            top: 93px;
            &:after {
                // background-color: #D84747;
                // background-color: rgba(#fff, 0.5);
                background-color: #51EEDA;
            }
        }
        &.a-2 {
            top: 283px;
            &:after {
                background-color: #D84747;
            }     
        }
        &.a-3 {
            top: 473px;
            &:after {
                background-color: #F6A678;
            }
        }
    }

    .b-period {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: -30px;
            bottom: -30px;
            left: 0;
            width: 1px;
            opacity: 0.2;
            border-right: 1px dashed #FFFFFF;
        }

        &.empty:after {
            content: none;
        }
    }

    .b-area {
        position: relative;
        height: 190px;
        width: 240px;

        &.a-1 {
            .b-area-content {
                .inner {
                    border-color: #51EEDA;
                }
                &.blch-eth .inner {
                    border-color: #627EEA;
                } 
                &.blch-bsc .inner {
                    border-color: #F3BA2F;
                } 
                &.blch-poly .inner {
                    border-color: #8247E5;
                } 
                &.blch-aval .inner {
                    border-color: #E84142;
                } 
            } 
        }
        &.a-2 {
            .b-area-content .inner {
                border-color: #D84747;
            }     
        }
        &.a-3 {
            .b-area-content .inner {
                border-color: #F6A678;
            }
        }    
    }

    
    .b-area-content {
        position: absolute;
        max-height: 180px;
        top: 50%;
        transform: translateY(-50%);

        font-weight: 300;
        font-size: 16px;
        line-height: 22px;

        width: auto;
        padding: 0 6px 0 8px;
        z-index: 5;

        .inner {
            background: #1E2121;
            box-sizing: border-box;
            border-radius: 20px;
            padding: 16px 20px;
            border: 1px solid transparent;
        }

        p {
            margin: 0;
        }

        &.w-50 {
            width: 50%;
        }
        &.w-75 {
            width: 75%;
        }
        &.w-100 {
            width: 100%;
        }
        &.w-125 {
            width: 125%;
        }
        &.w-150 {
            width: 150%;
        }

        &.ml-50 {
            left: 50%;
        }
        &.ml-25 {
            left: 25%;
        }
        &.ml-75 {
            left: 75%;
        }
    }

    .bp-title {
        position: absolute;
        color: #51EEDA;
        font-size: 14px;
        line-height: 130%;
        
        b {
            display: block;
            color: #fff;
        }

        &.top {
            left: 0;
            top: -80px;
        }

        &.bottom {
            left: 0;
            bottom: -80px;  
        }
    }

    .blch {
        display: flex;
        align-items: center;
        font-size: 18px;
        line-height: 1em;
        margin-bottom: 12px;
        img {
            margin-right: 10px;
            display: inline-block;
            width: 32px;
            height: 32px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}

// MicrodaoIntro -------------------- 
.sec-microdaointro {
    padding: 100px 0 80px;

    h1 {
        margin-bottom: 2px;
    }

    .h1_sub {
        font-weight: bold;
        margin: 0 0 2em;
    }

    .btn-outline {
        background: transparent;
        border: 1px solid #4afebf;
        color: #4afebf;
        margin: 30px auto 0;
    }


    .btn-fixed {
        position: fixed;
        max-width: 310px;
        margin-top: 32px;
    }

    .back-link {
        padding-bottom: 2em;
    }

    @media(min-width: $lg) {
        padding: 140px 0 80px;
  
        .microdaocard {
            .distrib__row {
                .row > div:first-child {
                    
                        text-align: right;
                    
                }
            }
        }

        .container {
            position: relative;
            min-height: 840px;
        }

        .btn-outline {
            margin: 40px 0 0;
        }

        .btn-fixed {
            margin-top: 135px;
        }
        h1 {
            margin-bottom: 12px;
        }
    }

    @media(max-width: $xl) {
        .btn-fixed {
            position: unset;
            margin-bottom: 32px;
            z-index: 100;
        }

    }

}

// MicrodaoForm -------------------- 

.sec-microdaoform {
    position: relative;
    padding: 100px 0 80px;

    h2 {
        margin-top: 32px;
        margin-bottom: 16px;
    }

    .input-control {
        width:100%;
        background:#fff;
        border:0;
        -webkit-box-sizing:border-box;
        box-sizing:border-box;
        border-radius:10px;
        height:55px;
        font-size:18px;
        line-height:21px;
        padding:18px 16px 14px;
    }

    .input-control::-webkit-input-placeholder {
        color:#acbec0;
    }

    .input-control::-moz-placeholder {
        color:#acbec0;
    }

    .input-control:-ms-input-placeholder {
        color:#acbec0;
    }

    .input-control:-moz-placeholder {
        color:#acbec0;
    }

    .input-control:focus {
        -webkit-box-shadow:0px 4px 50px rgba(102,253,208,.4);
        box-shadow:0px 4px 50px rgba(102,253,208,.4);
    }

    .input-control.disabled,.input-control:disabled {
        opacity:.4;
        color:#acbec0;
    }

    select.input-control{
        padding:16px 16px 14px;
        width:100%;
        -moz-appearance:none;
        -webkit-appearance:none;
        appearance:none;
        background-image:url("../pics/i-select.svg");
        background-position:right 14px top 50%;
        background-repeat:no-repeat;
    }

    .input-label {
        display:block;
        font-size:18px;
        line-height:21px;
        min-height:21px;
        margin-bottom:15px;
    }

    .submit-wrapper {
        text-align: center;
    }

    .input-submit {
        margin: 10px auto;
    }

    @media(min-width: $lg) {

        .distrib__row {
            .row > div:first-child {
                
                    text-align: right;
                    font-weight: bold;
                
            }
        }

    }

}

// MicrodaoList -------------------- 

.sec-microdaolist {
    position: relative;
    padding: 50px 0;

    h2 {
        margin-top: -16px;
        margin-bottom: 32px;
    }

    a {
        color: #4AFEBF !important;
        text-decoration: none;
        &:hover {
            color: #FFFFFF !important;
        }
    }

    .list-item {
        width: 100%;
        height: 12rem;
        position: relative;
        padding: 19px 16px 10px;
        color: #fff;
        font-size: 1.2em;
        font-weight: bold;
        flex-grow: 0;
        background-color: #1e2121;
        box-shadow: 0 5px 25px -10px rgb(0 0 0 / 16%);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;        
    }

    @media(min-width: $lg) {
        padding: 0 0 200px;
        h2 {
            margin-bottom: 56px;
        }
        .sec-bg {
            width: 320px;
            height: 710px;
        }
    }
    @media(min-width: $xl) {
        .sec-bg {
            width: 400px;
            height: 886px;
            top: 40px;
        }
    }
     
}


.footer-socials {
    margin-left: auto;
}

@media (max-width:767px) {
    .footer-socials {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 20px;
    }
    .s-footer .socials {
        justify-content: flex-start;
        flex-wrap: wrap;
    }    
}

.footer-contacts {
    margin-top: 20px;
    margin-inline: auto;
}

.subheader {
    font-size: 18px;
}