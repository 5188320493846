.s-header {
    height: 52px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $gray;
    z-index: 10;
    .container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    @media(min-width: $lg) {
        height: 80px;
    }
}

.header__logo {
    img {
        display: block;
        width: 110px;
        height: auto;
    }

    @media(min-width: $lg) {
        margin-right: 10px;
        position: relative;
        top: -2px;
    }

    @media(min-width: $xl) {
        margin-right: 40px;
        img {
            width: 156px;
        }
    }
}

.header__nav {
    display: none;
    position: fixed;
    width: 100%;
    height: calc(100% - 52px);
    left: 0;
    top: 52px;
    background-color: $black;
    z-index: 9;
    overflow: auto;
    padding-top: 52px;
    padding-bottom: 40px;

    .socials {
        justify-content: center;
        img {
            width: 30px;
            height: 30px;
        }
    }

    // .btn {
    //     margin: 0 auto 40px;
    //     width: 149px;
    //     height: 45px;
    // }



    @media(min-width: $lg) {
        position: static;
        height: auto;
        width: auto;  
        background: transparent;
        flex-grow: 1;
        display: flex !important; 
        align-items: center;
        justify-content: flex-end;
        padding: 0;
        overflow: visible;
        // .btn {
        //     margin: 0;
        // }
        .socials {
            img {
                width: 24px;
                height: 24px;
            }
        }
    }

    @media(min-width: $xl) {
        .socials {
            li {
                padding: 0 5px;
            }
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.header__menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 50px;
    
    a {
        display: block;
        text-decoration: none;
        color: #fff;
        font-weight: 700;
        padding: 15px 0;
        transition: color 0.2s ease;
        &:hover {
            color: $green1;
            svg {
                fill: $green1;
            }
        }
        svg {
            display: inline-block;
            vertical-align: baseline;
            position: relative;
            top: 4px;
            fill: #fff;
            width: 14px;
            height: 14px;
        }
    }

    @media(min-width: $lg) {
        display: flex;
        overflow: visible;
        font-size: 14px;
        line-height: 21px;
        margin: 0 36px 0 0;
        a {
            padding: 5px 8px;
            svg {
                width: 12px;
                height: 12px;
                top: 2px;
            }
        }

        li:last-child {
            a {
                padding-right: 0;
            }
        }
    }

    @media(min-width: $xl) {
        a { 
            padding: 5px 15px;
        }
    }
}


.header__menu-toggle {
    position: relative;
    height: 36px;
    width: 36px;
    cursor: pointer;

    span {
        display: block;
        width: 36px;
        height: 4px;
        position: absolute;
        left: 0;
        background: $grad;
        border-radius: 6px;
        transition: transform 0.2s ease, opacity 0.2s ease;

        &:nth-child(1) {
            top: 6px;
        }
        &:nth-child(2) {
            top: 16px;
        }
        &:nth-child(3) {
            top: 26px;
        }
    }

    &.open {
        span {
            &:nth-child(1) {
                top: 16px;
                transform: rotate(-45deg);
            }
            &:nth-child(2) {
                opacity: 0;
            }
            &:nth-child(3) {
                top: 16px;
                transform: rotate(45deg);
            }   
        }

        & + .header__nav {
            display: block;
        }
    }
}