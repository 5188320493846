@mixin text-main {
    font-size: 14px;
    // line-height: 21px;
    line-height: 1.5;

    @media(min-width: $lg) {
        font-size: 18px;
        // line-height: 21px;
    }
}

@mixin text-12-14 {
    font-size: 12px;
    line-height: 1.5;

    @media(min-width: $lg) {
        font-size: 14px;
    }
}

@mixin text-18 {
    font-size: 14px;
    line-height: 1.5;

    @media(min-width: $xl) {
        font-size: 18px;
    }
}

@mixin text-20-24 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 1.2em;
    @media(min-width: $lg) {
        font-size: 24px;
        line-height: 30px;
    }
}

@mixin text-18-24 {
    font-size: 18px;
    line-height: 30px;
    @media(min-width: $lg) {
        font-size: 24px;
        line-height: 30px;
    }
}


@mixin h1 {
    font-size: 36px;
    line-height: 50px;
    margin: 0 0 1.2em;
    @media(min-width: $lg) {
        font-size: 48px;
        line-height: 50px;
    }
}

@mixin h2 {
    font-size: 24px;
    line-height: 50px;
    margin: 0 0 1.2em;
    @media(min-width: $lg) {
        font-size: 36px;
        line-height: 50px;
    }
}

@mixin h3 {
    font-size: 18px;
    line-height: 30px;
    margin: 0 0 1.2em;
    @media(min-width: $xl) {
        font-size: 24px;
        line-height: 30px;
    }
}

// @mixin h3 {
//     font-size: 18px;
//     line-height: 30px;
//     margin: 0 0 1.2em;
//     @media(min-width: $lg) {
//         font-size: 24px;
//         line-height: 30px;
//     }
// }

